export const aboutData = [
  {
    id: 0,
    icon: "fa fa-book",
    title: "Mission",

    description: [
      "Empowering individuals and communities through comprehensive training, capacity building, and inclusive initiatives, RiseUp Training and Consulting is dedicated to fostering sustainable development. Our mission is to equip entrepreneurs, women, youth, persons with disabilities, and marginalized groups with the knowledge, skills, and resources needed to overcome challenges, unlock potential, and build thriving enterprises. We commit to delivering tailored programs, fostering diversity, and actively engaging with local communities to drive positive and lasting change.",
    ],
  },
  {
    id: 1,
    icon: "fa fa-globe",
    title: "Vision",
    description: [
      "Our vision is to emerge as the nationwide, trusted guiding influence in shaping a brighter, more equitable future by offering tailored solutions, actively engaging with communities, and implementing impactful initiatives that uplift and transform lives by 2030.",
    ],
  },
  {
    id: 2,
    icon: "fa fa-pencil",
    title: "Value",
    description: [
      "Commitment",
      "Honesty",
      "Making difference ",
      "Delivery",
      "Solution mindedness",
      "Persistence ",
      "Empathy",
    ],
  },
];
export const contactData = [
  {
    id: 0,
    icon: "fas fa-envelope",
    title: "Web Mail Address",
    description: "contact@riseupup.org",
  },
  {
    id: 1,
    icon: "fas fa-phone",
    title: "Phone Number",
    description: "(+251) 920745871- 0913714442",
  },
  {
    id: 2,
    icon: "fas fa-map-marker-alt",
    title: "Address",
    description: "Addis ababa, Yeka subcity, Metebaber Building 3rd Floor office no. 312",
  },
  {
    id: 3,
    icon: "fas fa-clock",
    title: "Working Day",
    description: "Monday- Friday 08:00 am - 5:00pm",
  },
];

export const servicesData = [
  {
    id: 1,
    icon: "fas fa-clone",
    title: "Training and Capacity Building",
    description:[
      {
        name:"Business Development",
        content:" Equip entrepreneurs and aspiring business owners with the knowledge, skills, and resources needed to start, grow, and sustain successful enterprises.",
        
      },
      {
        name:"Empowerment Workshops",
        content:" Provide empowerment workshops tailored to the unique needs and challenges faced by women, youth, PWDs, and other marginalized groups, focusing on building confidence, resilience, and self-esteem."
      },
      {
        name: "Career Development",
        content:"Offer career development programs to help individuals identify their strengths, interests, and goals, and develop actionable plans for career advancement and success."
      },
      {
        name:"Life Skills Training",
        content:" Deliver life skills training sessions covering essential skills such as communication, decision-making, problem-solving, financial literacy, and time management. "
      },
      {
        name:"Coaching and Mentorship",
        content:" Provide personalized coaching and mentorship services to support individuals in achieving their personal and professional goals."

      }
       
       
    
    ],
    showMore: false,
  },
  {
    id: 2,
    icon: "fas fa-snowflake",
    title: "Workshops and Events",
    description:[
      {
        name:"Tailored Workshops",
        content:" Organize customized workshops and events addressing specific needs and interests of target populations, including topics such as leadership development, financial management, entrepreneurship, experience sharing’s, labour linkages and supply chain creation activities." 
      
      },
      {
        name:"Capacity Building Events",
        content:"Host capacity-building events aimed at enhancing the skills, knowledge, and networks of women, youth, PWDs, and other vulnerable groups to facilitate their active participation in economic and social activities."
      }
    ],
      

    showMore: false,
  },
  {
    id: 3,
    icon: "fas fa-plug",
    title: "Project Design and Implementation",
    description:
    [
      {
        name:"Needs Assessment",
        content:" Conduct comprehensive needs assessments to identify gaps and challenges faced by target communities and develop tailored solutions to address them." 
         
      },
      {
        name:"Project Design",
        content:" Design innovative projects and initiatives focused on empowering women, youth, PWDs, and other vulnerable groups through skills development, economic empowerment, and social inclusion."
      },
      {
        name:"Project Implementation",
        content:"Implement projects in collaboration with local stakeholders, leveraging community resources and expertise to maximize impact and sustainability."
      },
      {
        name:"Monitoring and Evaluation",
        content:"Monitor project progress and evaluate outcomes to ensure accountability, learning, and continuous improvement"
      }
    ],
    showMore: false,
  },
];
export const blogData = [
  {
    id: 1,
    image: require("../assets/images/blog.jpg"),
    time: "07 Nov 2023",
    title: "Why Choose RiseUp Training and Consulting?",
    description:
    [
      {
        name:"Inclusive Approach",
        content:"We prioritize inclusively and diversity in all our programs and initiatives, ensuring that everyone has equal access to opportunities for growth and development." 
         
      },
      {
        name:"Customized Solutions",
        content:" Our solutions are tailored to meet the unique needs and aspirations of each community and individual, promoting relevance, effectiveness, and sustainability."
      },
      {
        name:"Community Engagement",
        content:"We actively engage with local communities, stakeholders, and partners to ensure that our interventions are contextually relevant and responsive to local needs.Impact-driven: We are committed to making a positive impact on the lives of our clients and the communities we serve, measuring success not only by outputs but also by outcomes and lasting change!."
      },
    ],
    showMore: false,
  },
];
export const heroData = [
  {
    id: 1,
    image: require("../assets/images/img-hero1.jpg"),
    title: "Welcome",
    description:
      "We are Dedicated to empowering individuals and organizations to reach their full potential.",
    // link: 'https://www.google.com'
  },
  {
    id: 2,
    image: require("../assets/images/img-hero2.jpg"),
    title: "Welcome",
    description:
      "We Scale up ideas and commitments through business development service models.",
    // link: 'https://www.facebook.com'
  },
  {
    id: 3,
    image: require("../assets/images/img-hero3.jpg"),
    title: "Welcome",
    description:
      "We Design resilience and resettlement programs for Internally Displaced Persons (IDPs).",
    // link: 'https://www.twitter.com'
  },
   {
    id: 4,
    image: require("../assets/images/img-hero2.jpg"),
    title: "Welcome",
    description:
      "We Build inclusive capacity-building programs that support individuals with disabilities to become economically self-sufficient and integrate seamlessly into societal activities.",
    // link: 'https://www.twitter.com'
  },
];
export const teamsData = [
  {
    id: 1,
    image: require("../assets/images/team1.jpg"),
    linkedinLink: "https://www.linkedin.com/in/habtegebriel-yohannes-07a7b7168/",
    name: "Habtegebriel Yohannes ",
    description:
      "Certified Trainer | Entrepreneur | Social Worker | MSME Coach |Civil Engineer",
  },
  {
    id: 2,
    image: require("../assets/images/team2.jpg"),
    linkedinLink: "https://www.linkedin.com/in/beaman-teoume/",
    name: "Beaman Teoumelessan ",
    description:
      "BDS Advisor , MSME coach , Entrepreneurship and Employability Specialist at SOS Children's Villages Ethiopia",
  },
  {
    id: 3,
    image: require("../assets/images/team3.jpg"),
    linkedinLink: "https://www.linkedin.com/in/kidist-kubie-71420764/",
    name: "Mrs.kidist kubie ",
    description:
      "Senior  professional  in HR, procurement, Supply chain & Logistics  Management and Administrations.MPA in public Administration , BA in management, BA in supply chain and BA in accounting.8 years of experience  in INGO,NGO,private sector and Government",
  },
  {
    id: 4,
    image: require("../assets/images/team4.jpg"),
    linkedinLink: "https://www.linkedin.com/in/mebratu-tilahun-212998140/",
    name: "Mebratu Tilahun ",
    description:
      "GBV advocacy and capacity building officer | Master Trainer on Business Skill | life Skill | Carreer Development | Employeblity | need assessment | certified Business and life skill Coach",
  },
];
export const testimonialsData = [
  {
    id: 1,
    name: "Mustefa Sadedin",
    description:
      "I was particularly impressed with Mebratu Tilahun's use of Training and Capacity Building in the program. It really helped me to understand the material and apply it to my work. The course materials were also very helpful, and I will continue to refer to them as I move forward.",
    designation: "Software Developer and ICT Expert",
  },
  {
    id: 2,
    name: "Abenet Asnak",
    description:
      "Since working with Habtegebriel Yohannes, we've seen a significant improvement in Project Design and Implementation. Their consultants were experts in Entrepreneurship, Social Worker and helped us develop a plan that was tailored to our specific needs. We're grateful for their guidance and support.",
    designation: "Computer Engineer",
  },
  {
    id: 3,
    name: "Seid Ageraman",
    description:
      "Mrs.kidist kubie's training program was fantastic! They were incredibly knowledgeable and engaging, and they made the learning process fun and interactive. I walked away with new skills and a fresh perspective on Workshops and Events. I highly recommend this program to anyone looking to improve their entrepreneurship,experience.",
    designation: "Msc.MBA",
  },
];
export const worksData = [
  {
    id: 1,
    link: "https://www.google.com",
    image: require("../assets/images/img1.jpg"),
    title: "Training and Capacity Building",
    subtitle: "Business Development ",
  },
  {
    id: 2,
    link: "https://www.google.com",
    image: require("../assets/images/img2.jpg"),
    title: "Training and Capacity Building",
    subtitle: "Life Skills Training",
  },
  {
    id: 3,
    link: "https://www.google.com",
    image: require("../assets/images/img3.jpg"),
    title: "Workshops and Events",
    subtitle: "Tailored Workshops",
  },
  {
    id: 4,
    link: "https://www.google.com",
    image: require("../assets/images/img4.jpg"),
    title: "Project Design and Implementation",
    subtitle: "Project Design: ",
  },
  {
    id: 5,
    link: "https://www.google.com",
    image: require("../assets/images/img5.jpg"),
    title: "Project Design and Implementation",
    subtitle: "Project Implementation ",
  },
  {
    id: 6,
    link: "https://www.google.com",
    image: require("../assets/images/img6.jpg"),
    title: "Project Design and Implementation",
    subtitle: "Monitoring and Evaluation",
  },
];
const headerData = [
  {
    id: 1,
    text: "Home",
    url: "/#",
  },
  {
    id: 2,
    text: "Services",
    url: "/#services",
  },
  {
    id: 3,
    text: "Works",
    url: "/#works",
  },
  {
    id: 4,
    text: "Our Team",
    url: "/#teams",
  },
  {
    id: 5,
    text: "Testimonials",
    url: "/#testimonials",
  },
  {
    id: 6,
    text: "Blog",
    url: "/#blog",
  },
  {
    id: 7,
    text: "About",
    url: "/#about",
  },
  {
    id: 8,
    text: "Contact",
    url: "/#contact",
  },
];
export default headerData;
