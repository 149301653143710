import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, { useState, useRef } from "react";
import { Alert } from 'react-bootstrap';
import emailjs from "@emailjs/browser";
import { contactData } from "../data/data";

function AppContact() {
  const [showAlert, setShowAlert] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
  
    emailjs
      .sendForm(
        "service_qpndq9j",
        "template_8060z1y",
        form.current,
        "YDokDiVPorBbpiFqQ"
      )
      .then(result => {
        if (result) {
          setShowAlert(true); 
          form.current.reset();
        } else {
          setShowAlert(false)
        }
      })
  };

  return (
    <section id="contact" className="block contact-block">
      <Container fluid>
        <Row>
        <Col>
        <div className="title-holder">
          <h2>Contact us</h2>
          <div className="subtitle">Get in Touch</div>
          <h3> Contact RiseUp Training and Consulting today to learn more about our services and how we can support your organization or community in achieving its goals. Together, let’s rise up and create a brighter future for all.</h3>
        </div>
        </Col>
        <Form style={{width:'500px', margin:'20px auto'}} className="contact-form" ref={form} onSubmit={sendEmail} >
           {showAlert && (
             <Alert variant="success" onClick={() => setShowAlert(false)}>
            Message Sent Successfully!
            </Alert>
           )}<br/>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control type="text" name="user_name" alt="Please fill out this field" placeholder="Full Name" required/><br/>
            <Form.Control type="email" name="user_email" alt="Please fill out this field" placeholder="Email" required/><br/>
            <Form.Control type="text" name="subject" alt="Please fill out this field" placeholder="Subject" /><br/>
            <Form.Control type="tel" name="contact" placeholder="Contact" /><br/>
            <Form.Control as="textarea" name="message" alt="Please fill out this field" placeholder="Message..." required/>
          </Form.Group>
          <div className="btn-holder">
            <Button type="submit"  value="Send">
              SEND MESSAGE
            </Button>
          </div>
        </Form>
        </Row>
      </Container>
    
      <div className="google-map">
        <div className="title-holder">
        <h2>Our Location</h2>
        </div>
        <iframe
          title="map"
          src="https://maps.google.com/maps?width=720&amp;height=400&amp;hl=en&amp;q=Addis%20ababa,%20Yeka%20subcity,%20Metebaber%20Building%203rd%20Floor%20office%20no.%20312+(Rise%20UP)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        ></iframe>
      </div><br/>
      <Container fluid>
       <Row>
          {
            contactData.map(contact => {
              return (
                <Col sm={3} className='contact-info' key={contact.id}>
                  <div className="icon">
                    <i className={contact.icon}></i>
                  </div>
                  <h3>{contact.title}</h3>
                  <p>{contact.description}</p>
                </Col>
              );
            })
          }
        </Row>
      </Container>
    </section>
  );
}

export default AppContact;
