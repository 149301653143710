import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState } from "react";
import { servicesData } from "../data/data";

function AppServices({des}) {
  const [services, setServices] = useState(servicesData);

  const handleShowMore = (index, showMore)=>{
    // const servicesList = [...services]
    // data.services[index].showMore = !data.services[index].showMore
    setServices( services.map(service =>{
      if(service.id === index){
        return {...service, showMore: !showMore }
      }else{
        return service
      }
    }))
    
  }
  // const [isOpen, setIsOpen] = useState(false)
  return (
    <section id="services" className="block services-block" >
      <Container fluid>
        <div className="title-holder">
          <h2>Our services</h2>
          <div className="subtitle">We're Here To Help Your Business Grow</div>
          <div className="subtitle">services we provide</div>
        </div>
        <Row>
          {services.map((service) => {
            return (
              <Col sm={4} className="holder" key={service.id} >
                <div className="icon">
                  <i className={service.icon}></i>
                </div>
                <h3>{service.title}</h3>
                {!service.showMore && 
                <ul>
                  
                  {service.description.map((desc,index)=>{
                      return(
                        <>
                        <li key={index}>
                          {desc.name}
                        </li>
                      </>
                      )

                  })}
                  {/* {service.description.name} */}
               
               </ul>
                
                
                
                }
                  {service.showMore && 
                    <ul>
                  
                  {service.description.map((desc,index)=>{
                      return(
                        <>
                        <li key={index}>
                          <strong>{desc.name}</strong>: {desc.content}
                        </li>
                        </>
                      )

                  })}
               
                {/* {service.description.name} */}
               </ul>
                }
                  <button onClick={()=>handleShowMore(service.id, service.showMore)} className='btn btn-primary'>{service.showMore? 'Hide':'Show'} Detail<i className='fas fa-chevron-right'></i> </button>
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
}

export default AppServices;
