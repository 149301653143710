import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { useState } from "react";
import { blogData } from "../data/data";

function AppBlog() {
  const [blogs, setNews] = useState(blogData);

  const handleShowMore = (index, showMore) => {
    setNews(
      blogs.map((blog) => {
        if (blog.id === index) {
          return { ...blog, showMore: !showMore };
        } else {
          return blog;
        }
      })
    );
  };

  return (
    <section id="blog" className="block blog-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Latest from blog</h2>
          <div className="subtitle">get our latest news from blog</div>
        </div>
        <Row>
          {blogs.map((blog) => {
            return (
              <Col sm={12} key={blog.id}>
                <div className="holder">
                  <Card>
                    <Card.Img variant="top" src={blog.image} />
                    <Card.Body>
                      <time>{blog.time}</time>
                      <Card.Title>{blog.title}</Card.Title>
                      <Card.Text>
                        {!blog.showMore && (
                          <ul>
                            {blog.description.map((desc, index) => {
                              return (
                                <>
                                  <li key={index}>{desc.name}</li>
                                </>
                              );
                            })}
                            {/* {service.description.name} */}
                          </ul>
                        )}
                        {blog.showMore && (
                          <ul>
                            {blog.description.map((desc, index) => {
                              return (
                                <>
                                  <li key={index}>
                                    <strong>{desc.name}</strong>: {desc.content}
                                  </li>
                                </>
                              );
                            })}

                            {/* {service.description.name} */}
                          </ul>
                        )}
                      </Card.Text>
                      <button
                        onClick={() => {
                          handleShowMore(blog.id, blog.showMore);
                        }}
                        className="btn btn-primary"
                      >
                        {blog.showMore ? "Hide" : "Show"}
                        <i className="fas fa-chevron-right"></i>{" "}
                      </button>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
}

export default AppBlog;
