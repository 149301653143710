import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../assets/images/logo.png";
import headerData from "../data/data";

function AppHeader() {
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="#home" className="logo">
          <img src={logo} width="70px" alt="logo"></img>
        </Navbar.Brand>
        <div className="title-motto">
          <h3>Rise Up Training and Consulting Services</h3>
          <h6>Rise UP and Lead: Inspire, Influence, Impact</h6>
        </div>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto">
            {headerData.map((header) => (
              <li key={header.id}>
                <Nav.Link href={header.url}>{header.text}</Nav.Link>
              </li>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default AppHeader;
