import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {aboutData} from '../data/data'


function AboutUs() {

  return (
    <section id="about" className="block about-block">
      <Container fluid>
        <div className="title-holder">
          <h2>About Us</h2>
        </div> 
        <Row>
          {
            aboutData.map(about => {
              return (
                <Col sm={4} className='holder' key={about.id}>
                  <div className="icon">
                    <i className={about.icon}></i>
                  </div>
                  <h3>{about.title}</h3>
                  <hr></hr>
                  <div className='overlay'>
                  <div className='about-list'>
                  <ul>
                      {about.description.map(
                        (desc, i) =>{
                          return(
                            <>
                            <li key={i}>{desc}</li>
                            </>
                          )
                        }
                      )}
                  </ul>
                  </div>
                  </div>
                </Col>
              );
            })
          }
        </Row>
             
      </Container>
    </section>
  );
}

export default AboutUs;